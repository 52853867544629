import React from 'react';
import ReactDOM from 'react-dom';
import { rootDomain } from './store/root-domain';
import { SharedHostGateway } from '@invent/platform-api';
import { baseHttpClient } from '@invent/http-client';
import { createInspector, addEffect, addEvent, addStore } from 'effector-inspector';

// bootstrap
// The import is necessary because the result of setting eager: true doesn't work as expected
// This is the equivalent solution
import '@invent/wlabel-shared-components';
import { updateFavicon } from '@/src/domains/branding/utils';

const SANDBOX_BOOTSTRAP_URI = 'https://icp-api.dev.apptrium.cloud/bootstrap';
const UAT_BOOTSTRAP_URI = 'https://icp-api.uat.apptrium.cloud/bootstrap';
const PROD_BOOTSTRAP_URI = 'https://icp-api.apptrium.cloud/bootstrap';

window.sharedHostGateway = new SharedHostGateway({});

function selectBootstrapURI() {
  const origin = window.location.origin;
  if (origin.includes('localhost') && process.env.NODE_ENV === 'development') {
    return `${origin}/bootstrap`;
  }
  if (origin.includes('.sandbox.apptrium.cloud')) {
    return SANDBOX_BOOTSTRAP_URI;
  }
  if (origin.includes('.rc.apptrium.cloud')) {
    return UAT_BOOTSTRAP_URI;
  }
  return PROD_BOOTSTRAP_URI;
}

// It is important to hold this code - or any rootDomain-dependent invocations -
// in main.tsx (right at the app entrypoint)
// so rootDomain will be initialized and shared into the scope before lib dependencies
// arrive in runtime, trying to locate if rootDomain is shared
if (process.env.NODE_ENV !== 'production') {
  rootDomain.onCreateStore(addStore);
  rootDomain.onCreateEffect(addEffect);
  rootDomain.onCreateEvent(addEvent);

  createInspector({ trimDomain: 'app' });
}

// async imports are here to delay appearance of modules that could have unresolved dependencies
async function main() {
  const { catchAndNotify, showNotification } = await import('./handlers');
  window.sharedHostGateway.registerNotifications({ catchAndNotify, showNotification });
  try {
    const bootstrapUrl = selectBootstrapURI();
    const envConfig = await fetch(bootstrapUrl);
    const envConfigJson = await envConfig.json();
    updateFavicon(envConfigJson.CUSTOMIZATION_BE);
    sessionStorage.setItem('tenentId', envConfigJson.TENENT_KEY);
    Object.assign(window, envConfigJson);

    baseHttpClient.interceptors.request.add((...args) => {
      const [_apiUrl, options] = args;
      const apiUrl = _apiUrl
        .replace('_internal_api_', window.API_BASE_URL)
        .replace('_fe_be_api_', window.CUSTOMIZATION_BE);
      return [apiUrl, options];
    });
  } catch (e) {
    catchAndNotify({
      message: 'Failed to get project configuration. Contact invent admin',
      traceId: 'bootstrap-1'
    });
  }

  const App = await import('./app');
  ReactDOM.render(<App.default />, document.getElementById('root'));
}

main();
