export function updateFavicon(apiUrl: string) {
  const url = `${apiUrl}/favicon.ico`;
  const link = document.querySelector("link[rel*='icon']");
  if (link) {
    link?.setAttribute('href', url);
  } else {
    const link = document.createElement('link');
    link.setAttribute('rel', 'icon');
    link.setAttribute('href', url);
  }
}
